<template>
  <ResendWithdrawalConfirmationLayout 
    :class="containerClasses"
    :email="getUserBasicInfo.email"
    :isLoading="isLoading"
    @onClose="onCloseModal"
    @onResend="onResend"
  />
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import ResendWithdrawalConfirmationLayout from './layout.vue';

export default {
  name: 'ResendWithdrawalConfirmation',
  components: {
    ResendWithdrawalConfirmationLayout,
  },
  data() {
    return {
      isLoading: false,
    };
  },
  computed: {
    ...mapGetters('user', ['getUserBasicInfo']),
    ...mapGetters('wallet', ['getWithdrawalId']),
    containerClasses(){
      return {
        'container--desktop': !this.isMobileDevice,
      }; 
    }
  },
  methods: {
    ...mapActions('wallet', ['resendEmailToConfirmWithdrawal']),
    onCloseModal(){
      this.hideModal();
    },
    async onResend(){
      try {
        this.isLoading = true;

        await this.resendEmailToConfirmWithdrawal({
          id: this.getWithdrawalId
        });

        this.showSuccess(this.$t('success.confirmation_email_sent'));

        this.onCloseModal();

      } catch (e) {
        this.showError(e);
      } finally {
        this.isLoading = false;
      }
    }
  }
};
</script>

<style scoped>
.container--desktop {
  max-width: 450px;
}
</style>
