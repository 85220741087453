<template>
  <div class="confirm-withdrawal-container">
    <div class="flex flex-col gap-s40">
      <div class="flex justify-center">
        <IconSendEmail class="icon fill-current text-text-positive" />
      </div>
      <div class="flex flex-col gap-s32">
        <h2 class="headline-small">
          {{ $t('wallet.modal.confirm_email.title') }}
        </h2>
        <p class="subheadline-medium">
          {{ $t('wallet.modal.confirm_email.sub_title') }}
          <span class="text-text-positive">
            {{ email }}
          </span>
        </p>
        <p class="body-text-x-space">
          {{ $t('wallet.modal.confirm_email.description') }}
        </p>
      </div>
    </div>
    <div class="flex flex-col mt-s40 gap-s16">
      <ButtonV2
        type="button"
        class="capitalize"
        size="medium"
        :label="$t('email.resend_email')"
         testId="btn-email-resend"
        :loading="isLoading"
        @onClick="$emit('onResend')"
        wide
      />
      <ButtonV2
        type="button"
        class="capitalize"
        version="secondary"
        size="medium"
        :label="$t('navigation.close')"
        testId="btn-close"
        :loading="isLoading"
        @onClick="$emit('onClose')"
        wide
      />
    </div>
  </div>
</template>

<script>
import { IconSendEmail } from '@/assets/icons';
import ButtonV2 from '@/stories/misc/ButtonV2';

export default {
  name: 'ResendWithdrawalConfirmationLayout',
  components: {
    ButtonV2,
    IconSendEmail
  },
  props: {
    email: {
      type: String,
      default: ''
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  }
};
</script>

<style scoped>
.confirm-withdrawal-container {
  @apply flex flex-col justify-between h-full pt-s64 px-s20 pb-s20;
}

.icon {
  font-size: 120px;
  width: 1em;
  height: 1em;
}
</style>
